import * as yup from 'yup'

import { yupSchemas } from '../../../../../lib/helpers/yupSchemas'
import {
  DefinedBenefitInput,
  PersonalAssetsInput,
  PropertyInput,
} from '../../utils/types'
import { PersonalAssetsInputFields } from './constants'

export function getInitialValues(
  previousData?: PersonalAssetsInput,
): PersonalAssetsInput {
  return {
    joint_current_savings: previousData?.joint_current_savings,
    joint_current_investments: previousData?.joint_current_investments,
    joint_has_properties: previousData?.joint_has_properties,
    joint_properties: fixMalformedProperties(
      previousData?.joint_properties || [],
    ),
    joint_has_rental_property: previousData?.joint_has_rental_property,

    user_current_savings: previousData?.user_current_savings,
    user_current_pension: previousData?.user_current_pension,
    user_current_investments: previousData?.user_current_investments,
    user_has_defined_benefit_scheme:
      previousData?.user_has_defined_benefit_scheme,
    user_defined_benefits: previousData?.user_defined_benefits || [],
    user_has_properties: previousData?.user_has_properties,
    user_properties: fixMalformedProperties(
      previousData?.user_properties || [],
    ),
    user_has_rental_property: previousData?.user_has_rental_property,

    partner_current_savings: previousData?.partner_current_savings,
    partner_current_pension: previousData?.partner_current_pension,
    partner_current_investments: previousData?.partner_current_investments,
    partner_has_defined_benefit_scheme:
      previousData?.partner_has_defined_benefit_scheme,
    partner_defined_benefits: previousData?.partner_defined_benefits || [],
    partner_has_properties: previousData?.partner_has_properties,
    partner_properties: fixMalformedProperties(
      previousData?.partner_properties || [],
    ),
    partner_has_rental_property: previousData?.partner_has_rental_property,
  }
}

export function fixMalformedProperties(
  properties: PropertyInput[],
): PropertyInput[] {
  return properties.map((property) => ({
    ...property,

    // Remove the `mortgage` if it's an empty array
    // TODO: Handle this with a Compass data migration on RQ-API
    mortgage:
      Array.isArray(property.mortgage) && property.mortgage.length === 0
        ? undefined
        : property.mortgage,
  }))
}

export function getValidationSchema() {
  return yup.object({
    [PersonalAssetsInputFields.user_current_savings]: yupSchemas
      .number()
      .required('Current value of cash savings is a required field'),
    [PersonalAssetsInputFields.user_current_pension]: yupSchemas
      .number()
      .required('Current value of pensions is a required field'),
    [PersonalAssetsInputFields.user_current_investments]: yupSchemas
      .number()
      .required('Current value of investments is a required field'),
  })
}

export function makeDefinedBenefitPlaceholder(): DefinedBenefitInput {
  return {
    defined_benefit_commence_month: undefined,
    defined_benefit_commence_year: undefined,
    defined_benefit_status: undefined,
    defined_benefit_annual_gross_benefit: undefined,
  }
}
