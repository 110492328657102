import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@rq-ratings/pro-solid-svg-icons'
import { INotyfOptions, Notyf } from 'notyf'
import React, { useContext } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { useCaptureErrorShownToUserEvent } from './useCaptureErrorShownToUserEvent'

function useNotyf() {
  const captureErrorShownToUserEvent = useCaptureErrorShownToUserEvent()

  // Create a monkey-patched Notyf instance so that whenever the notyf.error()
  // method is called, we capture it as an event in Posthog & Sentry and can
  // be notified.
  function createNotyfInstance() {
    const notyf = new Notyf(NotyfOptions)
    const originalNotyfErrorHandler = notyf.error

    const monkeyPatchedErrorHandler: Notyf['error'] = (...args) => {
      captureErrorShownToUserEvent()
      return originalNotyfErrorHandler.apply(notyf, args)
    }

    notyf.error = monkeyPatchedErrorHandler

    return notyf
  }

  const context = React.createContext(createNotyfInstance())

  return useContext(context)
}

const NotyfOptions: Partial<INotyfOptions> = {
  duration: 2500,
  position: {
    x: 'right',
    y: 'top',
  },
  types: [
    {
      type: 'default',
      background: '#3B7DDD',
      icon: { className: 'notyf__icon--success', tagName: 'i' },
    },
    {
      type: 'info',
      background: '#3B7DDD',
      icon: renderToStaticMarkup(<FontAwesomeIcon icon={faCircleInfo} />),
    },
    {
      type: 'success',
      background: '#28a745',
      icon: { className: 'notyf__icon--success', tagName: 'i' },
    },
    {
      type: 'warning',
      background: '#ffc107',
      icon: { className: 'notyf__icon--error', tagName: 'i' },
    },
    {
      type: 'danger',
      background: '#dc3545',
      icon: { className: 'notyf__icon--error', tagName: 'i' },
    },
  ],
}

export default useNotyf
