import ms from 'ms'
import { Props as ReactSelectProps } from 'react-select'

import { OrderDirection } from '../types/api'
import {
  CommercialAgreementReferralDirection,
  CommercialAgreementType,
  CompanyEmailType,
  CompanyIdentifierType,
  CompanyStatus,
  CompanyTypeCode,
  CompassPdfReportSection,
  CompassReportStatus,
  ConsentDecisionType,
  ConsentType,
  DoughnutChartSize,
  FcaType,
  FeePayBackType,
  FeeSharingType,
  NormalReferralTypeOption,
  PanelStatus,
  PendingPanelAction,
  RecommendedPartnerActionType,
  ReferralApprovalStatus,
  ReferralStatus,
  ReferralType,
  ReferralTypeOption,
  RqCertifiedAssessmentStage,
  RqCertifiedAssessmentStatus,
  RqPartnershipType,
  SelectOption,
  StateKey,
  StateType,
} from '../types/misc'
import { ROUTES } from './routes'
import { FEE_CATEGORY_OPTION_OTHER } from './services/serviceFeeService'

export const FEE_SHARING_TYPE = {
  percentage: 'PERCENTAGE',
  setAmount: 'SET_AMOUNT',
} satisfies Record<string, FeeSharingType>

export const SERVICE_FEE_CATEGORY_TYPES = {
  initialFees: {
    label: 'Initial fees',
    value: 'INITIAL_FEE',
  },
  ongoingFees: {
    label: 'Ongoing fees',
    value: 'ONGOING_FEE',
  },
  arrangementFee: {
    label: 'Arrangement fee',
    value: 'ARRANGEMENT_FEE',
  },
  totalFee: {
    label: 'Total fees',
    value: 'TOTAL_FEE',
  },
  other: {
    label: 'Other - please specify',
    value: FEE_CATEGORY_OPTION_OTHER,
  },
}

export const SERVICE_FEE_SHARE_TYPE = {
  fixedFee: {
    label: 'Fixed Fee',
    value: FEE_SHARING_TYPE.setAmount,
  },
  percentage: {
    label: 'Percentage',
    value: FEE_SHARING_TYPE.percentage,
  },
}

export const SERVICE_FEE_DURATION_OPTIONS: SelectOption[] = [
  { label: 'The life of the contract', value: '-1' },
  { label: '1 year', value: '1' },
  { label: '2 years', value: '2' },
  { label: '3 years', value: '3' },
  { label: '4 years', value: '4' },
  { label: '5 years', value: '5' },
  { label: '6 years', value: '6' },
  { label: '7 years', value: '7' },
  { label: '10 years', value: '10' },
]

export const SIDEBAR_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
}

export const SIDEBAR_BEHAVIOR = {
  STICKY: 'sticky',
  FIXED: 'fixed',
  COMPACT: 'compact',
}

export const LAYOUT = {
  FLUID: 'fluid',
  BOXED: 'boxed',
}

export const THEME = {
  DEFAULT: 'default',
  COLORED: 'colored',
  DARK: 'dark',
  LIGHT: 'light',
}

export const THEME_PALETTE_LIGHT = {
  bodyBackground: '#f7f9fc',
  bodyColor: '#212529',
  base: '#6c757d',
  blue: '#3B82EC',
  blueLight: '#E2F1FF',
  compassPdfTextColor: '#5D5D5D',
  primary: '#3B82EC',
  'primary-dark': '#1659c7',
  'primary-light': '#84aef2',
  secondary: '#495057',
  green: '#4BBF73',
  success: '#4BBF73',
  successDark: '#99D7AE',
  successLight: '#DDF2E4',
  successExtraLight: '#DDF2E4',
  info: '#F3FAFF',
  orange: '#f0ad4e',
  warning: '#f0ad4e',
  warningLight: '#FFD198',
  warningExtraLight: '#FFFBF6',
  red: '#d9534f',
  redExtraLight: '#FFE0DC',
  redLight: '#FFA295',
  tableStripedBg: '#f4f7f9',
  danger: '#d9534f',
  dangerLight: '#FF5353',
  dangerExtraLight: '#d88c8c',
  purple: '#7F4EBED9',
  disabled: '#e2e8ee',
  white: '#fff',
  gray: '#F7F8FA',
  '#D5D5D5': '#D5D5D5',
  grayBox: '#F8F8F8',
  grayLight: '#D1D4D7',
  'gray-100': '#f4f7f9',
  'gray-200': '#e2e8ee',
  'gray-300': '#dee6ed',
  'gray-400': '#ced4da',
  'gray-500': '#adb5bd',
  'gray-600': '#6c757d',
  'gray-700': '#495057',
  'gray-800': '#020202',
  'gray-900': '#212529',
  statLabelColor: '#b6b6b6',
  black: '#000',
  yellowDocuSign: '#ffc820',
  fontWeights: {
    bold: 500,
  },
}

export const CHART_PALETTE = {
  blue: '#46a1f8',
  yellow: '#ffa600',
  blueDark: '#2f79c0',
  orange: '#EC7A08',
  red: '#F8333C',
  pink: '#fb8489',
  pinkDark: '#bc5090',
  purple: '#58508d',
  green: '#89be28',
  greyLight: 'rgb(215,215,215)',
  yellowDark: '#e6d000',
  greenDark: '#6b941f',
  greyDark: 'rgba(107, 107, 107, 1)',
}

// Can merge this into the main palette if we need to use the colors outside
// the PDF report
export const COMPASS_PDF_REPORT_PALETTE = {
  blue: '#06B0F0',
}

// TODO: We're only using the light theme, so merge THEME_PALETTE_DARK and
// THEME_PALETTE_LIGHT into one THEME_PALETTE object
export const THEME_PALETTE_DARK = {
  ...THEME_PALETTE_LIGHT,
  'primary-dark': '#84aef2',
  'primary-light': '#1659c7',
  white: '#293042',
  'gray-100': '#3e4555',
  'gray-200': '#545968',
  'gray-300': '#696e7b',
  'gray-400': '#7f838e',
  'gray-500': '#9498a1',
  'gray-600': '#a9acb3',
  'gray-700': '#bfc1c6',
  'gray-800': '#d4d6d9',
  'gray-900': '#eaeaec',
  black: 'black',
  dangerAlternate: '#FF644F',
}

export const COMPASS_REPORT_STATUS = {
  INVITED: 'INVITED',
  COMPLETE: 'COMPLETE',
  IN_PROGRESS: 'IN_PROGRESS',
} satisfies Record<CompassReportStatus, CompassReportStatus>

export const CONSENT_TYPE: Record<ConsentType, ConsentType> = {
  ICAEW: 'ICAEW',
}
export const CONSENT_DECISION: Record<
  ConsentDecisionType,
  ConsentDecisionType
> = {
  OPT_IN: 'OPT_IN',
  OPT_OUT: 'OPT_OUT',
}

export const COMPANY_IDENTIFIER: Record<
  CompanyIdentifierType,
  CompanyIdentifierType
> = {
  frn: 'frn',
  companyId: 'companyId',
}

export const COMPANY_STATUS: Record<CompanyStatus, CompanyStatus> = {
  SUSPENDED: 'SUSPENDED',
  CLAIMED: 'CLAIMED',
}

export const COMPANY_TYPE_CODE: Record<CompanyTypeCode, CompanyTypeCode> = {
  ifa: 'ifa',
  solicitor: 'solicitor',
  accountant: 'accountant',
  mortgage_adviser: 'mortgage_adviser',
  business_lending_non_fca: 'business_lending_non_fca',
  insurance_adviser: 'insurance_adviser',
  corporate_finance: 'corporate_finance',
  corporate_finance_non_fca: 'corporate_finance_non_fca',
  investment_management: 'investment_management',
  auto_enrolment: 'auto_enrolment',
  business_coach: 'business_coach',
  conveyancing: 'conveyancing',
  employee_benefits_provider: 'employee_benefits_provider',
  estates_admin: 'estates_admin',
  estates_agent: 'estates_agent',
  estate_planner: 'estate_planner',
  management_consultant: 'management_consultant',
  marketing_consultant: 'marketing_consultant',
  property_developer: 'property_developer',
  software_provider: 'software_provider',
  surveyor: 'surveyor',
  venture_capital: 'venture_capital',
  other: 'other',
}

export const COMPANY_TYPE_EMAIL: Record<CompanyEmailType, CompanyEmailType> = {
  COMPASS_INVITE: 'COMPASS_INVITE',
}

export const COMMERCIAL_AGREEMENT_TYPE: Record<
  CommercialAgreementType,
  CommercialAgreementType
> = {
  NO: 'NO',
  YFS: 'YFS',
  YJV: 'YJV',
  RQP: 'RQP',
}

export const COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION: Record<
  CommercialAgreementReferralDirection,
  CommercialAgreementReferralDirection
> = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING',
}

export const EMAIL_ADDRESSES = {
  rqHello: 'hello@rqratings.com',
  rqSupport: 'support@rqratings.com',
}

export const FEE_PAY_BACK_TYPE = {
  percentage: 'percentage',
  setAmount: 'set_amount',
} satisfies Record<string, FeePayBackType>

export const RQ_PARTNERSHIP_TYPE = {
  percentage: 'percentage',
  setAmount: 'set_amount',
  none: 'none',
} satisfies Record<string, RqPartnershipType>

export const RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE = {
  notStarted: 'NOT_STARTED',
  pendingPaymentConfirmation: 'PENDING_PAYMENT_CONFIRMATION',
  inProgress: 'IN_PROGRESS',
  inReview: 'IN_REVIEW',
  passed: 'PASSED',
  failed: 'FAILED',
} satisfies Record<string, RqCertifiedAssessmentStatus>

export const RQ_CERTIFIED_ASSESSMENT_STAGE = {
  one: 'one',
  two: 'two',
} satisfies Record<RqCertifiedAssessmentStage, RqCertifiedAssessmentStage>

// See https://date-fns.org/docs/format for date formats.
export const DATE_FORMATS = {
  DAY_MONTH_YEAR: 'dd/LL/yyyy', // 05/03/2002
  DAY_MONTH_YEAR_TIME: 'dd/LL/yyyy, h:mm a', // 05/03/2002, 2:53 PM
  MONTH_YEAR: 'LL/yyyy', // 05/2002
  YEAR_MONTH_DAY: 'yyyy-LL-dd', // 2002-05-03
  MONTH_DAY_YEAR: 'MMM d, yyyy', // May 3, 2002
  MONTH_DAY_YEAR_TIME: 'MMM d, yyyy h:mm a', // May 3, 2002 2:53 PM
}

export const ERROR_MESSAGES = {
  genericServerError:
    'Something went wrong on our side. Our team has been notified.',
  required: 'This field is required',
}

export const FCA_MONITORING_CODE = {
  CompaniesHouse: 'CompaniesHouse',
  FCA: 'FCA',
  FOS: 'FOS',
  FSCS1: 'FSCS1',
  FSCS2: 'FSCS2',
}

export const ORDER_DIRECTION: Record<OrderDirection, OrderDirection> = {
  asc: 'asc',
  desc: 'desc',
}

export const FCA_SERVICE_TYPE_NAMES = {
  independent: 'Independent',
  restrictedNotWholeOfMarket: 'Restricted - Not whole of market',
  restrictedWholeOfMarket: 'Restricted - Whole of market',
  no: 'No',
}

export const FCA_SERVICE_TYPE_RESTRICTION_LEVELS = {
  independent: 1,
  restrictedNotWholeOfMarket: 2,
  restrictedWholeOfMarket: 3,
  no: 4,
}

export const LOCAL_STORAGE_KEYS = {
  apiCommitHash: 'apiCommitHash',
  previouslySelectedScope: 'previouslySelectedScope',
  previouslySelectedCompanyId: 'previouslySelectedCompanyId', // Only read this when refreshing the page to pre-select company
  officeWebAccessToken: 'officeWebAccessToken',
  officeWebRefreshToken: 'officeWebRefreshToken',
}

export const PANEL_STATUS: Record<PanelStatus, PanelStatus> = {
  requested: 'requested',
  approved: 'approved',
  rejected: 'rejected',
}

export const PENDING_PANEL_ACTION: Record<
  PendingPanelAction,
  PendingPanelAction
> = {
  addCommercialAgreement: 'addCommercialAgreement',
  chaseCommercialAgreementReview: 'chaseCommercialAgreementReview',
  respondToInvite: 'respondToInvite',
  reviewCommercialAgreement: 'reviewCommercialAgreement',
}

export const COOKIES = {
  referringCompanyReferralCode: 'rq.referring_company_referral_code',
}

const DUMMY_HUBSPOT_MEETING_LINK =
  'https://meetings-eu1.hubspot.com/james-valentine'

const IS_DEV = process.env.NODE_ENV === 'development'

export const EXTERNAL_LINKS = {
  dpbLicence:
    'https://www.icaew.com/regulation/dpb-investment-business-licence',
  marketingWebsite: 'https://rqratings.com',
  hubspot: {
    bookDemoLink: IS_DEV
      ? DUMMY_HUBSPOT_MEETING_LINK
      : 'https://meetings-eu1.hubspot.com/johnny-ridd',
    bookSlotWithRqLink: IS_DEV
      ? DUMMY_HUBSPOT_MEETING_LINK
      : 'https://meetings-eu1.hubspot.com/johnny-ridd/rq-demo',
  },
  privacyPolicy: 'https://rqratings.com/privacy-policy',
  rqCertified: 'https://www.rqratings.com/rq-certified',
  vimeo: {
    inviteToRq: 'https://player.vimeo.com/video/818424533?h=806c1e01ef',
    makeReferral: 'https://player.vimeo.com/video/818431321?h=e4ba16bd74',
    manageReferrals: 'https://player.vimeo.com/video/818422648?h=9ed2453ad9',
    manageRelationships:
      'https://player.vimeo.com/video/818423647?h=762c07dac0',
    compass: 'https://player.vimeo.com/video/818428419?h=5dec9b0238',
  },
}

export const FCA_ACCREDITATIONS = [
  { code: 'CISI', name: 'CISI Accredited' },
  { code: 'CII', name: 'CII Chartered' },
  { code: 'BCorp', name: 'BCorp' },
  { code: 'CEFEX', name: 'CEFEX' },
]

export const FCA_INDIVIDUAL_QUALIFICATIONS = [
  { code: 'Resolution', name: 'Resolution' },
  { code: 'SOLLA', name: 'SOLLA' },
  { code: 'STEP', name: 'STEP' },
  { code: 'STEP TEP', name: 'STEP TEP' },
  { code: 'STEP Full Member', name: 'STEP full member' },
]

export const FCA_SERVICE_TYPE_IDS = {
  independent: 1,
  restrictedNotWholeOfMarket: 2,
  restrictedWholeOfMarket: 3,
}

export const RECOMMENDED_PARTNER_ACTION_TYPE: Record<
  RecommendedPartnerActionType,
  RecommendedPartnerActionType
> = {
  DISMISS: 'DISMISS',
  ACCEPT_WITH_TERMS: 'ACCEPT_WITH_TERMS',
  ACCEPT_WITHOUT_TERMS: 'ACCEPT_WITHOUT_TERMS',
}

export const REFERRAL_TYPE: Record<ReferralType, ReferralType> = {
  direct: 'direct',
  indirect: 'indirect',
}

export const REFERRAL_TYPE_OPTION: Record<
  ReferralTypeOption,
  ReferralTypeOption
> = {
  direct: 'direct',
  directCc: 'directCc',
  directPco: 'directPco',
  indirect: 'indirect',
  bookCallForClient: 'bookCallForClient',
}

export const REFERRAL_APPROVAL_STATUS: Record<
  ReferralApprovalStatus,
  ReferralApprovalStatus
> = {
  approved: 'approved',
  pending: 'pending',
  rejected: 'rejected',
}

export const NORMAL_REFERRAL_TYPE_OPTION: Record<
  NormalReferralTypeOption,
  NormalReferralTypeOption
> = {
  direct: 'direct',
  directCc: 'directCc',
  directPco: 'directPco',
  indirect: 'indirect',
}

export const REFERRAL_STATUS_ID = {
  PENDING: 1,
  AWAITING_CLIENT_TO_SCHEDULE_MEETING: 2,
  AWAITING_ADVISER_TO_CONTACT_CLIENT: 3,
  AWAITING_CLIENT_CONSENT: 50,
  INITIAL_MEETING_SCHEDULED: 450,
  COMPLETE: 500,
}

export const REFERRAL_STATUS: Record<ReferralStatus, ReferralStatus> = {
  awaiting_client_consent: 'awaiting_client_consent',
  awaiting_client_meeting: 'awaiting_client_meeting',
  awaiting_client_schedule_meeting: 'awaiting_client_schedule_meeting',
  complete: 'complete',
  initial_meeting_held: 'initial_meeting_held',
  initial_meeting_scheduled: 'initial_meeting_scheduled',
  live: 'live',
  won_completed: 'won_completed',
  not_progressing: 'not_progressing',
  unable_contact_client: 'unable_contact_client',
  awaiting_client_response: 'awaiting_client_response',
  client_details_passed_to_pco: 'client_details_passed_to_pco',
}

export const SERVICE_AREA_ID_CREATION_OF_A_FINANCIAL_PLAN = 6
export const SERVICE_AREA_ID_WILL = 1070

export const MAX_FILE_SIZE_BYTES = 20000000

// Max range for MySQL integer types is 2147483647
// https://tinyurl.com/mysql-integer-types
export const MAX_INTEGER_VALUE = 2000000000

// Assuming we're using the MySQL DECIMAL(10,2) type for money columns
export const MAX_MONEY_VALUE = 1000000000

export const STATE_KEY = {
  hasInteractedWithFirstRelationshipRequestApprovedModal:
    'interacted_with_first_relationship_request_approved_modal',
  hasWatchedDemoVideo: 'watched_demo_video',
  hasInteractedWithRqCertifiedAnnouncement:
    'interacted_with_rq_certified_announcement',
  hasClientReviewedPartners: 'client_reviewed_partners',
  panelApprovedNew: 'panel_approved_new',
  panelApprovedUpdated: 'panel_approved_updated',
  panelRequestedNew: 'panel_requested_new',
  panelRequestedUpdated: 'panel_requested_updated',
  referralFromNew: 'referral_from_new',
  referralFromUpdated: 'referral_from_updated',
  referralToNew: 'referral_to_new',
  referralToUpdated: 'referral_to_updated',
} satisfies Record<string, StateKey>

// TODO: rename THEME_PALETTES to THEME_PALETTE
export const THEME_PALETTE = THEME_PALETTE_LIGHT

export const STATE_TYPE: Record<StateType, StateType> = {
  user: 'user',
  company: 'company',
  companyUser: 'companyUser',
}

export const COMMON_DATA_ERROR_MESSAGE =
  'Common data endpoint returned empty results'

export const MONTH_OPTION_LABELS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const FCA_TYPE: Record<FcaType, FcaType> = {
  appointed_representative: 'appointed_representative',
  directly_authorised: 'directly_authorised',
  trading_name: 'trading_name',
}

export const DOUGHNUT_CHART_SIZE: Record<DoughnutChartSize, number> = {
  sm: 100,
  md: 140,
}

export const COMPASS_BALANCE_SHEET_TOTAL_KEY = 'total'

export const COMPASS_REPORT_PAGE_NAMES = {
  cover: 'Cover',
  tableOfContents: 'Table of contents',
  introduction: 'Introduction',
}

export const PDF_DIMENSIONS = {
  A4: {
    landscapeWidth: '297mm',
    landscapeHeight: '210mm',
    portraitWidth: '210mm',
    portraitHeight: '297mm',
  },
}
export const IMPORT_FILE_NOTIFICATION_DURATION = ms('7s')

export const PDF_PATHS = [
  ROUTES.compassPdfReportSection,
  ROUTES.compassPdfGuide,
  ROUTES.referralESignatureConsentPdf,
  ROUTES.rqCertifiedPdfGuides,
  ROUTES.rqCertifiedNdaPdf,
]

export const COMPASS_REPORT_SECTION = {
  one: '1',
  two: '2',
  three: '3',
  four: '4',
} satisfies Record<string, CompassPdfReportSection>

export const COMPASS_PALETTE = {
  orange: '#F18F01',
  turquoise: '#81D2C7',
  blue: '#06B0F0',
}

export const BASE_FONT_SIZE = 13.2

export const LONG_MESSAGE_DURATION = 7000

export const REGULATOR_CODE = {
  icaew: 'ICAEW',
  acca: 'ACCA',
  icas: 'ICAS',
  sra: 'SRA',
  ls: 'LS',
  other: 'OTHER',
}

export const REGULATOR_ID = {
  other: 100,
}

export const API_VIOLATION_CODE = {
  attachmentSize: 'app-attachment-size',
  invalidEnumValue: 'invalid-enum-value',
  missingCommercialTerms: 'app-commercial-agreement-no-service-fee',
  unapprovedOrUnamendedConsentLetter:
    'app-commercial-agreement-unreviewed-consent-letter',
}

export const RQ_DETAILS = {
  companyNumber: '13202073',
  address: '71-75 Shelton Street, Covent Garden, London, WC2H 9JQ',
}

export const LOCATION_STATE = {
  skipNavigationPrompt: 'skipNavigationPrompt',
}

export const EXISTING_AGREEMENT_MAX_FILE_SIZE_MB = 20

export const POSTHOG_EVENT = {
  compassBulkInviteError: 'Compass bulk invite error',
  login: 'Login',
  logout: 'Logout',
  errorMessageShownToUser: 'Error message shown to user',
  switchCompany: 'Switch Company',
  switchClient: 'Switch Client',
}

export const DEFAULT_REACT_SELECT_PROPS: ReactSelectProps = {
  menuPlacement: 'auto',
}
