import posthog from 'posthog-js'
import { useCallback } from 'react'

import { POSTHOG_EVENT } from '../lib/constants'
import sentryService from '../lib/services/sentryService'
import { useSessionMetadata } from './useSessionMetadata'

export function useCaptureErrorShownToUserEvent() {
  const sessionMetadata = useSessionMetadata()

  return useCallback(() => {
    console.log('Capturing error shown to user event')

    sentryService.captureMessageWithId({
      messageId: 'error-message-shown-to-user',
      message: 'Error message shown to user',
      extra: { sessionMetadata },
    })

    posthog.capture(POSTHOG_EVENT.errorMessageShownToUser)
  }, [sessionMetadata])
}
