import React from 'react'

import { ROUTES } from '../../../lib/routes'
import { StepName } from './InviteToRqModal'
import InviteTypeButton from './InviteTypeButton'

interface Props {
  onSelect: (newStepName?: StepName) => void
}

const InviteTypeChoice: React.FC<Props> = ({ onSelect }) => {
  return (
    <div className="row gap-3">
      <InviteTypeButton
        iconName="barChart"
        text="Invite your referral partners to RQ"
        onClick={(): void => onSelect('referralPartners')}
      />

      <InviteTypeButton
        iconName="groupOfPeople"
        text="Invite a colleague to join your account on RQ"
        href={ROUTES.manageUsers}
        onClick={(): void => onSelect()}
      />
    </div>
  )
}

export default InviteTypeChoice
